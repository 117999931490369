import React from 'react';
import { Button, Container, Header, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import DefaultPageLayout from '../../components/DefaultPageLayout';

const PageSegment = styled(Segment)`
  padding: 8em 0 !important;
`;

const Lead = styled.div`
  font-size: 2em;
  font-weight: 500;
  text-align: center;
`;

export default () => (
  <DefaultPageLayout title="Hire a Google Developers Expert | RxJS Course">
    <PageSegment vertical>
      <Container>
        <Header as="h1">Hire Us</Header>
        <Lead>
          <p>We love RxJS, Angular, React, and Node. We'd be thrilled to work with you.</p>
          <p>Need help? Hire the best for your project.</p>
        </Lead>
      </Container>
    </PageSegment>
    <PageSegment vertical textAlign="center">
      <Button
        href="https://liveloveapp.com"
        content="Learn More About LiveLoveApp"
        icon="arrow right"
        labelPosition="right"
      />
    </PageSegment>
  </DefaultPageLayout>
);
